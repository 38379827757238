import React from "react"
import Seo from "../components/seo"
import "../styles/theme.scss"
import Layout from "../layouts/layout";
import { graphql } from "gatsby"

const NotFound = ({ data, location }) => {
    let global = data.contentfulWebsite;
    return (
        <Layout pathname={location.pathname}>
            <Seo metaTitle={global.title} metaDescription={global.description} author={global.author} shareImage={global.shareImage.file.url} />
            <div className="o-wrapper o-content o-container o-content-container o-content-container--large">
                <img className="u-border" alt="Press" src={data.contentfulPress.heroImg.resize.src} />
                <div className="o-flex o-flex--half">
                    {data.contentfulPress.cards.map((column, index) =>
                        <div key={index}>
                            <a href={column.url} target="_blank" className="c-press u-border" style={{backgroundImage: `url(${column.image.resize.src})`}}>
                                <img className="c-press__img" alt={column.title} src={column.logo.resize.src} />
                            </a>
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
};

export default NotFound

export const pageQuery = graphql`
{
    contentfulWebsite(title: {eq: "Black Ice"}) {
        author
        description
        title
        shareImage {
            file {
                url
            }
        }
    }
    contentfulPress(website: {title: {eq: "Black Ice"}}) {
        heroImg {
            resize(width: 1900) {
                src
            }
        }
        cards {
            title
            url
            logo {
                resize(width: 200) {
                    src
                }
            }
            image {
                resize(width: 600, height: 400) {
                    src
                }
            }
        }
    }
}
`;
